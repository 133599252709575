import logoLink from '../resources/lvm.svg';

export const cssVariables = {
    active: '#009B31',
    inactive: '#CCC',

    primary_light: '#D9EFBB',
    primary: '#009B31',
    primary_dark: '#007229',
    text_on_primary: '#fff',
    primary_transparent: '#f3f8ec',

    secondary: '#4A4949',
    secondary_light: '#999999',
    text_on_secondary: 'white',
    secondary_transparent: '#D6D6D6',

    font_color: '#4A4949',
    link_color: '#009B31',

    font: 'Roboto, Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#009B31',
    activate_color: '#D6D6D6',
    deactivate_color: '#D6D6D6',
    delete_color: '#CD3111',    

    dropdown_background: '#D9EFBB',

};

export const logo = logoLink;
